
import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import PricingAreaTwo from "../components/PricingAreaTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";
import AboutArea from "../components/AboutArea";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import serviceList from "../scripts/serviceList";
import Tour_PricingPlan from "../components/Tour_PricingPlan";
import Tour_SubBanner from "../components/Tour_SubBanner";
import ServiceAreaSix from "../components/ServiceAreaSix";
import Tour_IndustryInsights from "../components/Tour_IndustryInsights";
import FaqAreaOne from "../components/FaqAreaOne";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FaqAreaThree from "../components/FaqAreaThree";


const bannerData = [
    {
      subtitle: 'Effortless Employee Scheduling with RosterInsight',
      title: 'Simplify Shift Planning and Enhance Workforce Efficiency',
      description:
        'RosterInsight offers a comprehensive scheduling solution that enables you to create complex shift patterns, manage absences seamlessly, and ensure optimal staffing levels. Our intuitive interface reduces administrative tasks, allowing you to focus on core business operations.',
      image: 'assets/img/banner/Schedule.jpg',
      buttons: [
        { text: 'Try For Free', link: '/contact', className: 'btn btn-border-base' },
        { text: 'Book A Demo', link: '/contact', className: 'btn btn-black' }
      ]
    }
  ];

const testimonialData = [
    {
      name: 'John Doe',
      designation: 'Operations Manager',
      review: 'RosterInsight has transformed our scheduling process, making it more efficient and transparent. The time clock feature ensures accurate payroll, and the support team is always responsive to our needs.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/1.png',
      rating: 5
    },
    {
      name: 'Jane Smith',
      designation: 'HR Director',
      review: 'The intuitive interface and comprehensive features of RosterInsight have streamlined our workforce management. The ability to manage absences and holidays seamlessly has been a game-changer for our organization.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/2.png',
      rating: 5
    },
  ];

  const services = [
    
    {
      title: 'Streamlined Operations',
      description:
        'Automating scheduling and time tracking reduces manual workloads, allowing managers to allocate time to strategic tasks.',
      image: 'assets/img/home-6/1.png',
      link: '/service-details',
    },
    {
      title: 'Enhanced Employee Satisfaction',
      description:
        'Providing a user-friendly platform for shift management and time tracking boosts employee engagement and morale.',
      image: 'assets/img/home-6/2.png',
      link: '/service-details',
    },
    {
      title: 'Cost Optimization',
      description:
        'Efficient scheduling and payroll management contribute to significant cost savings, enhancing overall profitability.',
      image: 'assets/img/home-6/3.png',
      link: '/service-details',
    },
  ];

  const featuredata = [
    {
      id: 1,
      title: "Create RosterInsight with ease",
      subtitle: "Advanced Shift Planning",
      description: "Design intricate shift schedules, including rotating and split shifts, to meet diverse operational needs.",
      image: "assets/img/about/10.png", // Replace with actual image path
    },
    {
      id: 2,
      title: "Accurate Time Tracking Made Simple",
      subtitle: "Absence and Holiday Management",
      description: "Approve and track employee absences and holiday requests directly through the platform, ensuring transparency and compliance.",
      image: "assets/img/about/10.png", // Replace with actual image path
     
    },
    {
      id: 3,
      title: "Manage Your Workforce On-the-Go",
      subtitle: "Time Clock and Timesheets",
      description: "Utilize GPS-enabled time tracking for accurate attendance records, streamlining payroll processes and minimizing errors.",
      image: "assets/img/about/10.png", // Replace with actual image path
    //   listItems: [
    //     "Instant notifications for shift changes",
    //     "Manage schedules remotely",
    //     "View real-time employee status",
    //     "Secure and user-friendly interface",
    //     "Available on iOS and Android"
    //   ]
    },
    {
      id: 4,
      title: "Simplified Payroll and Invoicing",
      subtitle: "Pay and Charge Management",
      description: "Manage hourly pay rates and billing efficiently, ensuring equitable compensation and healthy operational revenue.",
      image: "assets/img/about/10.png", // Replace with actual image path
    }
  ];

  const ctaActionData = {
    subtitle: "",
    title: "Ready to Transform Your Workforce Management?",
    content:
      "Experience the benefits of RosterInsight's Employee Scheduling Software. Contact us today to schedule a demo or start your free trial.",
    buttons: [
      {
        label: "Contact Us",
        link: "/contact",
        className: "btn btn-border-base",
      },
      {
        label: "Start Free Trial",
        link: "/contact",
        className: "btn btn-black",
        style: { marginLeft: "8px" },
      },
    ],
  };


  const industrydata = [
    { title: "Security" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "HealthCare" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Events" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Stadia" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Temporary Staffing" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Venues" ,imgSrc: "assets/img/blog/1.png",link: "/"},
  ];

  const faqData = [
    {
      question: "What is staff scheduling software?",
      answer:
        "Staff scheduling software automates your employee scheduling process to reduce administrative workload. You can create complex schedules in seconds, fill shifts quickly with suitable people, track time and attendance, and control costs to manage healthy profits."
    },
    {
      question: "Can I track payroll information with RosterInsight?",
      answer:
        "Yes! You can set up custom pay rates for each shift. When your employees clock in with GPS Time Clock app, timesheets with payroll information will be automatically created."
    },
    {
      question: "Can I manage break times with RosterInsight?",
      answer:
        "Certainly! You can set up customized break rules, and your employees can clock in and out using GPS Time Clock from their mobile devices."
    },
    {
      question: "What other benefits does RosterInsight provide?",
      answer: "You can manage absences and track holiday pay. RosterInsight also lets you set up custom working time rules to ensure compliance with labor laws."
    },
    {
      question: "Is RosterInsight a good solution for staffing agencies?",
      answer: "RosterInsight is an excellent solution for staffing agencies as we have tailored specific features like Pay & Charge and Client Portal exactly for these use cases."
    },
    {
      question: "How is RosterInsight different from other staff management systems?",
      answer: "RosterInsight has always been an advanced scheduling solution, providing cutting-edge optimization for large enterprises to reduce administrative work and overhead costs. At the same time, RosterInsight is easy to use and only charges you for scheduled hours, providing risk-free management of temporary workforce and growth opportunities for mid-sized companies."
    }
  ];

const Tour_EmployeeScheduling = () => {

  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarTwo />

      {/* Banner Two */}
      <Tour_SubBanner bannerData={bannerData}/>

      {/* About Area Two */}
      <AboutArea data={featuredata}/>

      <ServiceAreaSix services={services} title="Why Use InsightRota App? Top 3 Benefits"/>

      {/* Testimonial One */}
      <TestimonialOne testimonialData={testimonialData}/>

      <Tour_PricingPlan data={ctaActionData}/>
      
      
      <Tour_IndustryInsights data={industrydata} title="Industries Finding Success with Our Staff Scheduling Software"/>

      {/* Blog Area Two */}
      <BlogAreaTwo />

      <FaqAreaOne data={faqData}/>

      {/* Footer Two */}
      <FooterTwo />
    </>
  );
};

export default Tour_EmployeeScheduling;
