import React from "react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaCalendarAlt,
  FaChevronRight,
  FaFacebookF,
  FaRegFolderOpen,
  FaInstagram,
  FaPlus,
  FaTwitter,
  FaRegUser,
  FaCalendar
} from "react-icons/fa";
import { Link } from "react-router-dom";

const ResourcesArea = () => {
  return (
    <>
      {/* =============== Blog Group start ===============*/}
      <div className='blog-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            {/* <div className='col-lg-8'> */}
              <div className='col-lg-6 single-blog-inner'>
                <div className='thumb'>
                  <img src='assets/img/blog/1.png' alt='img' />
                  {/* <span className='date'>15 DEC</span> */}
                </div>
                <div className='details'>
                  <ul className='blog-meta'>
                    <li>
                      <FaCalendarAlt /> 18/09/2024

                    </li>
                    <li>
                      <FaRegFolderOpen /> Healthcare staffing
                    </li>
                  </ul>
                  <h2 className='title'>
                    <Link to='/blog-details'>
                      A Beginner's Guide to Running a Successful Agricultural
                    </Link>
                  </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribut It is a long established fact
                    that a reader will be distracted by the readable content of
                    a page when looking at its layout.
                  </p>
                  <Link
                    className='btn btn-border-base mt-3'
                    to='/service-details'
                  >
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
              <div className='col-lg-6 single-blog-inner'>
                <div className='thumb'>
                  <img src='assets/img/blog/2.png' alt='img' />
                  {/* <span className='date'>15 DEC</span> */}
                </div>
                <div className='details'>
                  <ul className='blog-meta'>
                    <li>
                      <FaCalendarAlt /> 18/09/2024
                    </li>
                    <li>
                      <FaRegFolderOpen /> Healthcare staffing
                    </li>
                  </ul>
                  <h2 className='title'>
                    <Link to='/blog-details'>
                      Successful Agricultural Guide to Running Beginner
                    </Link>
                  </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribut It is a long established fact
                    that a reader will be distracted by the readable content of
                    a page when looking at its layout.
                  </p>
                  <Link
                    className='btn btn-border-base mt-3'
                    to='/service-details'
                  >
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
              <div className='col-lg-6 single-blog-inner'>
                <div className='thumb'>
                  <img src='assets/img/blog/3.png' alt='img' />
                  {/* <span className='date'>15 DEC</span> */}
                </div>
                <div className='details'>
                  <ul className='blog-meta'>
                    <li>
                      <FaCalendarAlt /> 18/09/2024
                    </li>
                    <li>
                      <FaRegFolderOpen /> Healthcare staffing
                    </li>
                  </ul>
                  <h2 className='title'>
                    <Link to='/blog-details'>
                      A Beginner's Guide to Running a Successful Agricultural
                    </Link>
                  </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribut It is a long established fact
                    that a reader will be distracted by the readable content of
                    a page when looking at its layout.
                  </p>
                  <Link
                    className='btn btn-border-base mt-3'
                    to='/service-details'
                  >
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
              <div className='pagination'>
                <Link className='prev page-numbers' to="#">
                  <FaAngleLeft />
                </Link>
                <Link className='page-numbers' to="#">
                  1
                </Link>
                <span className='page-numbers current'>2</span>
                <Link className='page-numbers' to="#">
                  3
                </Link>
                <Link className='page-numbers' to="#">
                  4
                </Link>
                <Link
                  className='next page-numbers'
                  to="#"
                >
                  <FaAngleRight />
                </Link>
              </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* =============== Blog Group End ===============*/}
    </>
  );
};

export default ResourcesArea;
