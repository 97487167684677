import React from "react";
import { Link } from "react-router-dom";

const Tour_PricingPlan = ({data}) => {
  // Dynamic pricing plan data


  return (
    <>
      {/* =============== About Area Three Start ===============*/}
      <div className='about-area bg-gradient-gray pd-top-90 pd-bottom-40'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-12 wow animated fadeInRight'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 text-center'>
                {data?.subtitle && <h6 className='sub-title'>{data?.subtitle}</h6>}
                <h2 className='title'>{data.title}</h2>
                <p className='content mb-4'>{data.content}</p>

                {data?.buttons?.map((button, index) => (
                  <Link
                    key={index}
                    className={button.className}
                    to={button.link}
                    style={button.style || {}}
                  >
                    {button.label}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Three End =============== */}
    </>
  );
};

export default Tour_PricingPlan;
