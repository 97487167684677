import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

// Example data array with image and content


const AboutArea = ({data}) => {
  return (
    <>
      <div className='about-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          {data.map((item, index) => (
            <div
              className='row about-content-wrapper mb-5'
              key={item.id}
              data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              {/* Conditional layout: if index is even, show image on left */}
              {index % 2 === 0 ? (
                <>
                      <div
                          className='col-lg-6 mb-4 mb-lg-0 '
                          data-aos='fade-right'
                          data-aos-delay='100'
                          data-aos-duration='1500'
                        >
                          <div className='about-thumb-inner'>
                            <img
                              className='main-img'
                              src={item.image}
                              alt={`About-${item.id}`}
                            />
                          </div>
                    </div>
                    <div
                      className='col-lg-6 '
                      data-aos='fade-left'
                      data-aos-delay='100'
                      data-aos-duration='1500'
                    >
                      <div className='section-title mb-0'>
                      <h6 className='sub-title'>{item?.subtitle}</h6>
                      <h2 className='title'>{item.title}</h2>
                      <p className='content mb-4'>
                        {item?.description}
                      </p>
                      <div className="row">
                        {item.listItems && item.listItems.length > 0 && (
                          <>
                            <div className="col-md-6">
                              <ul className="single-list-inner style-check style-heading mb-3">
                                {item.listItems.slice(0, 2).map((listItem, idx) => (
                                  <li key={idx}>
                                    <FaCheckCircle /> {listItem}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul className="single-list-inner style-check style-heading mb-3">
                                {item.listItems.slice(2).map((listItem, idx) => (
                                  <li key={idx}>
                                    <FaCheckCircle /> {listItem}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                      <Link className='btn btn-border-base' to='/about'>
                        Discover More <FaPlus />
                      </Link>
                      </div>
                    </div>
                </>
              ) : (
                <>
                  {/* If index is odd, show content first, then image */}
                    <div
                      className='col-lg-6 '
                      data-aos='fade-left'
                      data-aos-delay='100'
                      data-aos-duration='1500'
                    >
                      <div className='section-title mb-0'>
                      <h6 className='sub-title'>{item?.subtitle}</h6>
                      <h2 className='title'>{item.title}</h2>
                      <p className='content mb-4'>
                      {item?.description}
                      </p>
                      <div className="row">
                        {item.listItems && item.listItems.length > 0 && (
                          <>
                            <div className="col-md-6">
                              <ul className="single-list-inner style-check style-heading mb-3">
                                {item.listItems.slice(0, 2).map((listItem, idx) => (
                                  <li key={idx}>
                                    <FaCheckCircle /> {listItem}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul className="single-list-inner style-check style-heading mb-3">
                                {item.listItems.slice(2).map((listItem, idx) => (
                                  <li key={idx}>
                                    <FaCheckCircle /> {listItem}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                      <Link className='btn btn-border-base' to='/about'>
                        Discover More <FaPlus />
                      </Link>
                      </div>
                    </div>
                    <div
                          className='col-lg-6 mb-4 mb-lg-0 '
                          data-aos='fade-right'
                          data-aos-delay='100'
                          data-aos-duration='1500'
                        >
                          <div className='about-thumb-inner'>
                            <img
                              className='main-img'
                              src={item.image}
                              alt={`About-${item.id}`}
                            />
                          </div>
                    </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AboutArea;
