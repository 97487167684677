import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
const BannerTwo = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* ================== BannerTwo Start ==================*/}
      <div
        className="banner-area-60 bg-relative banner-area-2 bg-cover"
        // className="banner-area bg-relative banner-area-2 bg-cover"
        // style={{ backgroundImage: 'url("./assets/img/bg/8.png")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner pe-xl-5">
                <h6
                  className="subtitle "
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  Streamline Your Workforce with RosterInsight
                </h6>
                <h2
                  className="title "
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  {' '}
                  Effortless Workforce Management for Maximum Productivity
                </h2>
                <p
                  className="content pe-xl-5 "
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                >
                  Empower your business with an all-in-one solution designed to simplify employee scheduling, task management, and real-time tracking. Whether you're managing a small team or a large organization, RosterInsight provides the tools you need to optimize efficiency and ensure smooth operations.
                </p>
                <Link
                  className="btn btn-border-base "
                  data-aos="fade-right"
                  data-aos-delay="300"
                  data-aos-duration="1500"
                  to="/about"
                >
                  Try For Free
                </Link>
                <div
                  className="d-inline-block align-self-center mt-4 mt-md-0"
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="1500"
                >
                  <Link
                    className="btn btn-black"
                    data-aos="fade-right"
                    data-aos-delay="300"
                    data-aos-duration="1500"
                    to="/about"
                    style={{marginLeft:'8px'}}
                  >
                    Book A Demo
                  </Link>
                  <div></div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-9 align-self-center"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="banner-thumb-2 mt-4 mt-lg-0">
                    <img
                      className="main-img"
                      src="assets/img/banner/Schedule.jpg"
                      alt="img"
                    />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerTwo End ==================*/}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="XM6kTQPzzpQ"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default BannerTwo;
