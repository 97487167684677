import React from "react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaCalendarAlt,
  FaChevronRight,
  FaFacebookF,
  FaRegFolderOpen,
  FaInstagram,
  FaPlus,
  FaTwitter,
  FaRegUser,
  FaCalendar
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Tour_IndustryInsights = ({data , title}) => {
  return (
    <>
      {/* =============== Blog Group start ===============*/}
      <div className='blog-area pd-top-120'>
        <div className='container'>
        <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>Industries</h6>
                <h2 className='title'>
                    {title}
                </h2>
                {/* <p className="content mb-4">
                  Read our blogs and case studies to discover how RosterInsight has helped businesses like yours streamline operations and improve efficiency.
                </p> */}
              </div>
            </div>
          </div>
          <div className='row'>
            {data?.map((post, index) => (
              <div key={index} className='col-lg-4 single-blog-inner'>
                <div className='thumb'>
                  <img src={post.imgSrc} alt='blog' />
                </div>
                <div className='details'>
                  <h2 className='title'>
                    <Link to={post.link} style={{ fontSize: '22px' }}>
                      {post.title}
                    </Link>
                  </h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =============== Blog Group End ===============*/}
    </>
  );
};

export default Tour_IndustryInsights;
