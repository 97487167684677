import React from 'react';
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FooterTwo = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className="footer-area footer-area-2 bg-gray mt-0 pd-top-90" style={{backgroundColor:'#0E4C6A'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              {/* <div className="widget widget_about"> */}
                <div className="thumb">
                  <Link><h2 style={{color:"white"}}>RosterInsight</h2></Link>
                  {/* <img src="assets/img/logo2.png" alt="img" /> */}
                </div>
                <div className="details">
                  <p>
                    Streamline Your Workforce with RosterInsight
                  </p>
                </div>
              {/* </div> */}
            </div>
            <div className="col-lg-2 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Company</h4>
                <ul>
                  <li>
                    <Link to="/service">
                      Why Choose Us?
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Careers
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Product</h4>
                <ul>
                  <li>
                    <Link to="/pricing">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Help Center
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Industries</h4>
                <ul>
                  <li>
                    <Link to="/service">
                      Security
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Healthcare
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Temporary Staffing
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Cleaning
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Hospitality
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Retail
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Childcare
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
            <div className="col-lg-2 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Features</h4>
                <ul>
                  <li>
                    <Link to="/service">
                      Employee Scheduling
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Time and Attendance
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Leave and Absences
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Pay and Charge
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      HR Data Management
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Events Staffing
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Guard Tours
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
            <div className="widget widget_nav_menu">
                <h4 className="widget-title">Resources</h4>
                <ul>
                  <li>
                    <Link to="/pricing">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Case Studies
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Refer a Friend
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Contact us</h4>
                <div className="widget widget_contact">
                  <ul className="details">
                    <li className='text-white'>
                      <FaMapMarkedAlt />
                      4517 Washington Ave. Manchester, Kentucky 39495
                    </li>
                    <li className="mt-3 text-white">
                      <FaPhoneAlt /> (+888) 123 456 765
                    </li>
                    <li className="mt-2 text-white">
                      <FaEnvelope /> infoname@mail.com
                    </li>
                  </ul>
                  <ul className="social-media mt-4">
                    <li>
                      <Link to="#">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaYoutube />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© RosterInsight 2024 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <Link to="#">Trams &amp; Condition</Link>
                <Link to="#">Privacy Policy</Link>
                <Link to="#">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterTwo;
