import React from "react";
import BlogGroup from "../components/BlogGroup";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import ResourcesArea from "../components/ResourcesArea";
import NavbarTwo from "../components/NavbarTwo";
import FooterTwo from "../components/FooterTwo";

const Blog = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo/>

      {/* Navigation Bar */}
      <Breadcrumb title={"Workforce management ideas and product announcements"} />

      {/* Blog Group
      <BlogGroup /> */}

      <ResourcesArea/>

       {/* Footer Two */}
       <FooterTwo />
    </>
  );
};

export default Blog;
